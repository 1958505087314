<template>
  <div class="col-12 col-lg-6 g c">
    <div class="card">
        <div class="card-body">
            <div class="row" v-if="!method">
                <div class="col-12 g">
                    كيف تريد اضافة الطلاب؟
                </div>
                <div class="col-12 col-lg-4 c g">
                    <div class="card card-body border text-center item" @click="method = 'manual'">
                        اضافة يدوياً
                    </div>
                </div>
                <div class="col-12 col-lg-4 c g">
                    <div class="card card-body border text-center item" @click="method = 'noor'; listenNoor()">
                        من ملف الارشاد
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 c g" v-if="students.length">
                <div class="form-group">
                  <label for="">
                    عدد مرات السحب في اليوم
                  </label>
                  <input type="number"
                    class="form-control" v-model="perday">
                </div>
                <div class="form-group">
                  <label for="">
                    المبلغ المسموح به في كل مرة
                  </label>
                  <input type="number"
                    class="form-control" v-model="amount">
                </div>
                <div class="col-12 g text-center">
                    <button class="btn btn-primary" @click="addNow()">اضافة الطلاب الآن</button>
                </div>
            </div>
        </div>
        <div class="card-footer" v-if="method">
            <div class="col-12" v-if="method == 'manual'">
                <div class="form-group">
                  <label for="">
                    الاسم
                  </label>
                  <input type="text"
                    class="form-control" v-model="name">
                </div>
                <div class="form-group">
                  <label for="">
                    رقم الهوية
                  </label>
                  <input type="text"
                    class="form-control" v-model="number">
                </div>
                <div class="form-group">
                  <label for="">
                    الجوال
                  </label>
                  <input type="text"
                    class="form-control" v-model="phone">
                </div>
                <div class="form-group">
                  <label for="">
                    عدد مرات السحب في اليوم
                  </label>
                  <input type="number"
                    class="form-control" v-model="perday">
                </div>
                <div class="form-group">
                  <label for="">
                    المبلغ المسموح به في كل مرة
                  </label>
                  <input type="number"
                    class="form-control" v-model="amount">
                </div>
                <div class="form-group">
                  <label for="">
                    ملاحظات (تظهر في بطاقة المصروف)
                  </label>
                  <input type="text"
                    class="form-control" v-model="notes">
                </div>
                <div class="col-12 text-center g">
                    <button class="btn btn-primary" @click="add()">اضافة الطالب</button>
                </div>
            </div>
            <div class="col-12" v-if="method == 'noor'">
                <div v-if="students.length == 0">
                    ملف الإرشاد الطلابي/كشف الطلاب من نظام نور
                    <br /><br />
                    <input
                    id="ingredient_file"
                    type="file"
                    />
                    <div id="progress-wrp">
                    <div class="progress-bar"></div>
                    <div class="status">0%</div>
                    </div>
                </div>
                <div class="col-12 table-responsive" v-if="students.length">
                    <table class="table table-hover table-bordered">
                        <thead>
                            <th>
                                الاسم
                            </th>
                            <th>
                                الهوية
                            </th>
                            <th>
                                الجوال
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="student in students" :key="student.number">
                                <td>
                                    {{ student.name }}
                                </td>
                                <td>
                                    {{ student.number }}
                                </td>
                                <td>
                                    {{ student.phone }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            method: null,
            students: [],
            step: 0,
            name: "",
            phone: "",
            number: "",
            perday: 2,
            amount: 5,
            notes: "",
            jwt: JSON.parse(localStorage.getItem("user")).jwt
        }
    },
    created(){
        var g = this;
    },
    methods: {
        add(){
            var g = this;
            $.post(api + '/user/students/add', {
                jwt: this.jwt,
                arr: [
                    {
                        name: g.name,
                        phone: g.phone,
                        amount: g.amount,
                        perday: g.perday,
                        number: g.number,
                        notes: g.notes
                    }
                ]
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم اضافة الطالب بنجاح")
                    g.name = ""
                    g.phone = ""
                    g.number = ""
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال")
            })
        },
        listenNoor() {
        var g = this;
        var Upload = function (file) {
            this.file = file;
        };

        Upload.prototype.getType = function () {
            return this.file.type;
        };
        Upload.prototype.getSize = function () {
            return this.file.size;
        };
        Upload.prototype.getName = function () {
            return this.file.name;
        };
        Upload.prototype.doUpload = function () {
            var that = this;
            var formData = new FormData();

            // add assoc key values, this will be posts values
            formData.append("file", this.file, this.getName());
            formData.append("upload_file", true);

            $.ajax({
            type: "POST",
            url: "https://private-api.brmja.com/excel",
            xhr: function () {
                var myXhr = $.ajaxSettings.xhr();
                if (myXhr.upload) {
                myXhr.upload.addEventListener(
                    "progress",
                    that.progressHandling,
                    false
                );
                }
                return myXhr;
            },
            success: function (data) {
                // your callback here
                $("#progress-wrp").fadeOut("slow");
                data.forEach(function (student) {
                if (student[5]) {
                    student[5] = student[5].toString();
                    if (
                    parseInt(student[5]) != "NanN" &&
                    student[5].trim() != "" &&
                    !student[5].includes("ا")
                    ) {
                    g.students.push({
                        name: student[4],
                        phone: student[1],
                        number: student[5],
                        perday: 2,
                        amount: 5
                    });
                    }
                }
                });
            },
            error: function (error) {
                // handle error
                alert("حدث خطأ");
            },
            async: true,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            timeout: 60000,
            });
        };

        Upload.prototype.progressHandling = function (event) {
            var percent = 0;
            var position = event.loaded || event.position;
            var total = event.total;
            var progress_bar_id = "#progress-wrp";
            if (event.lengthComputable) {
            percent = Math.ceil((position / total) * 100);
            }
            // update progressbars classes so it fits your code
            $(progress_bar_id + " .progress-bar").css("width", +percent + "%");
            $(progress_bar_id + " .status").text(percent + "%");
        };
        setTimeout(() => {
            $("#ingredient_file").on("change", function (e) {
            $("#progress-wrp").fadeIn("slow");
            var file = $(this)[0].files[0];
            var upload = new Upload(file);

            // maby check size or type here with upload.getSize() and upload.getType()

            // execute upload
            upload.doUpload();
            });
        }, 1000);
        },
        addNow(){
            var g = this;
            $.post(api + '/user/students/add', {
                jwt: this.jwt,
                arr: g.students.map(function(x){
                    x.perday = g.perday
                    x.amount = g.amount
                    return x;
                })
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    alert("تم اضافة الطلاب بنجاح")
                    location.reload()
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال")
            })
        },
    }
}
</script>

<style>
.item{
    border-bottom: solid 3px #ddd !important;
}
.item:hover{
    border-bottom: solid 3px green !important;
    cursor: pointer;
    color: #fff;
    background: #7EAA92;
}
</style>
<style scoped>
#progress-wrp {
  border: 1px solid #0099cc;
  padding: 1px;
  position: relative;
  height: 30px;
  border-radius: 3px;
  margin: 10px;
  text-align: left;
  background: #fff;
  box-shadow: inset 1px 3px 6px rgba(0, 0, 0, 0.12);
  display: none;
}

#progress-wrp .progress-bar {
  height: 100%;
  border-radius: 3px;
  background-color: greenyellow;
  width: 0;
  box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.11);
}

#progress-wrp .status {
  top: 3px;
  left: 50%;
  position: absolute;
  display: inline-block;
  color: #000000;
}
</style>